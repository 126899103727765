<template>
    <a-tabs v-model:activeKey="activeKey" @tabClick="tabClick">
        <a-tab-pane :key="-1" tab="全部"> </a-tab-pane>
        <a-tab-pane v-for="(item, index) in configList" :key="index" :tab="item.appName" :disabled="loading">
        </a-tab-pane>
    </a-tabs>
    <XATable :columns="columns" :dataSource="tableData" :scroll="{ x: 1500 }" :loading="loading">

        <template #estimatedEarnings="{ scope }">
            <span>${{ (scope.record.estimatedEarnings / 100).toFixed(2) }}</span>
        </template>
        <template #observedEcpm="{ scope }">
            <span>${{ (scope.record.observedEcpm / 100).toFixed(2) }}</span>
        </template>
        <template #impressionCtr="{ scope }">
            <span>{{ (scope.record.impressionCtr * 100).toFixed(2) }}%</span>
        </template>
        <template #matchRate="{ scope }">
            <span>{{ (scope.record.matchRate * 100).toFixed(2) }}%</span>
        </template>
        <template #control="{ scope }">
            <a-button :disabled="activeKey == -1" type="link" @click="confirmDetail(scope.record)">详情</a-button>
        </template>
    </XATable>
    <!-- <div class="pagination">
        <a-pagination :current="pageNo" :total="total" :pageSize="pageSize" @change="pageChange"
            :showSizeChanger="false"></a-pagination>
    </div> -->
    <XDialog :visible="detailVisible" :title="adsTitle" xWidth="100%" @cancel="detailVisible = false">
        <XATable :columns="detailColumns" :dataSource="detailTableData" :scroll="{ x: 1500 }" :loading="detailLoading">
            <template #estimatedEarnings="{ scope }">
                <span>${{ (scope.record.estimatedEarnings / 100).toFixed(2) }}</span>
            </template>
            <template #observedEcpm="{ scope }">
                <span>${{ (scope.record.observedEcpm / 100).toFixed(2) }}</span>
            </template>
            <template #impressionCtr="{ scope }">
                <span>{{ (scope.record.impressionCtr * 100).toFixed(2) }}%</span>
            </template>
            <template #matchRate="{ scope }">
                <span>{{ (scope.record.matchRate * 100).toFixed(2) }}%</span>
            </template>
        </XATable>
    </XDialog>
</template>

<script lang="ts" setup>
import Vue, {
    onMounted,
    ref,
    watch,
    defineAsyncComponent,
    onBeforeUnmount,
    computed,
} from "vue";
import { admDateList, configAdmList, admDateListAdUnit } from "@/api/api";
const XATable = defineAsyncComponent(() => import("../components/XATable.vue"));
import XDialog from "@/components/XDialog.vue";
import { notification } from "ant-design-vue";
import { onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
import dayjs, { Dayjs } from "dayjs";

const props = defineProps<{
    appData: any;
    dateTime: any;
    //   isConsume: boolean;
    isRefresh: boolean;
    dbName: String;
}>();
const currentAppId = ref("");
const rightAppIdVal = ref<any[]>([]);
var appIdList: any[] = [];
const maxTagCount = ref(1);
const checkedValue = ref(true);
const configList = ref<any>([]);
const dateValue = ref("");
const pageNo = ref(1);
const activeKey = ref(-1)
const detailVisible = ref(false)
const detailLoading = ref(false)
const adsTitle = ref("详情")
const curDetailRow = ref<any>({})

const columns = ref([
    {
        title: "日期",
        key: "date",
        dataIndex: "date",
    },
    {
        title: "请求数",
        key: "adRequests",
        dataIndex: "adRequests",
    },
    {
        title: "点击数",
        key: "clicks",
        dataIndex: "clicks",
    },
    {
        title: "展示数",
        key: "impressions",
        dataIndex: "impressions",
    },
    {
        title: "点击率",
        key: "impressionCtr",
        dataIndex: "impressionCtr",
        slots: {
            customRender: "impressionCtr",
        },
    },
    {
        title: "成功请求数",
        key: "matchedRequests",
        dataIndex: "matchedRequests",
    },
    {
        title: "媒合率",
        key: "matchRate",
        dataIndex: "matchRate",
        slots: {
            customRender: "matchRate",
        },
    },
    {
        title: "有效千次曝光出价",
        key: "observedEcpm",
        dataIndex: "observedEcpm",
        slots: {
            customRender: "observedEcpm",
        },
    },
    {
        title: "预估收入",
        key: "estimatedEarnings",
        dataIndex: "estimatedEarnings",
        slots: {
            customRender: "estimatedEarnings",
        },
    },
    {
        title: "操作",
        key: "control",
        dataIndex: "control",
        slots: {
            customRender: "control",
        },
    },
]);
const detailColumns = ref([
    {
        title: "广告位",
        key: "adUnitName",
        dataIndex: "adUnitName",
    },
    {
        title: "请求数",
        key: "adRequests",
        dataIndex: "adRequests",
    },
    {
        title: "点击数",
        key: "clicks",
        dataIndex: "clicks",
    },
    {
        title: "展示数",
        key: "impressions",
        dataIndex: "impressions",
    },
    {
        title: "点击率",
        key: "impressionCtr",
        dataIndex: "impressionCtr",
        slots: {
            customRender: "impressionCtr",
        },
    },
    {
        title: "成功请求数",
        key: "matchedRequests",
        dataIndex: "matchedRequests",
    },
    {
        title: "媒合率",
        key: "matchRate",
        dataIndex: "matchRate",
        slots: {
            customRender: "matchRate",
        },
    },
    {
        title: "有效千次曝光出价",
        key: "observedEcpm",
        dataIndex: "observedEcpm",
        slots: {
            customRender: "observedEcpm",
        },
    },
    {
        title: "预估收入",
        key: "estimatedEarnings",
        dataIndex: "estimatedEarnings",
        slots: {
            customRender: "estimatedEarnings",
        },
    },
]);
const tableData = ref([]);
const detailTableData = ref([]);
const loading = ref(false);
let timerInval = ref();
var BackOrderIds: any[] = [];

const getRequestData = () => {
    loading.value = true;
    admDateList({
        appId: currentAppId.value,
        // pageNo: pageNo.value,
        // pageSize: pageSize.value,
        beginTime: dayjs(dateValue.value[0]).format("YYYY-MM-DD"),
        endTime: dayjs(dateValue.value[1]).format("YYYY-MM-DD"),
    }).then((res) => {
        loading.value = false;
        res.data.forEach((element: any) => {
            if (!element.date) {
                element.date = "汇总"
            }
        });
        tableData.value = res.data;
    });
};
// 监听 Vuex 的 refreshNeeded 状态
const refreshNeeded = computed(() => store.state.refundRefreshNeeded);

// 监控 refreshNeeded 的变化
watch(refreshNeeded, (newValue) => {
    if (newValue) {
        // 执行页面刷新操作
        getRequestData();
        // 重置刷新状态
        store.dispatch("resetRefundRefresh");
    }
});

watch(
    () => props.isRefresh,
    (cur, old) => {
        if (rightAppIdVal.value.length) {
            getRequestData();
        }
    }
);

watch(
    () => props.dateTime,
    (cur, old) => {
        dateValue.value = cur;
        getRequestData();
    },
    {
        immediate: true,
    }
);

const tabClick = (tabIndex: any) => {
    if (tabIndex === -1) {
        currentAppId.value = "";
    } else {
        const item: any = configList.value[tabIndex];
        currentAppId.value = item.appId;
    }
    getRequestData();
};
const pageChange = (page: any) => {
    pageNo.value = page;
    getRequestData();
};
const getConfigList = () => {
    configAdmList({}).then((res) => {
        console.log(res)
        configList.value = res.data;
    });
};
const confirmDetail = (row: any) => {
    curDetailRow.value = row
    adsTitle.value = row.appName + "广告详情" + "(" + row.date + ")"
    detailVisible.value = true
    detailLoading.value = true
    var beginTime = row.date
    var endTime = row.date
    if (row.date === "汇总") {
        beginTime = dayjs(dateValue.value[0]).format("YYYY-MM-DD")
        endTime = dayjs(dateValue.value[1]).format("YYYY-MM-DD")
    }
    admDateListAdUnit({
        appId: row.appId,
        beginTime: beginTime,
        endTime: endTime
    }).then(res => {
        detailLoading.value = false
        detailTableData.value = res.data
    })
}
onMounted(() => {
    checkedValue.value = store.state.refundCheckedValue;
    dateValue.value = props.dateTime;
    getConfigList();
    getRequestData();
});
</script>
<style>
.a-tab-pane-content {
    overflow-x: none !important;
}
</style>